.navbar{
    height: 50px;
    width: 100%;
    background-color: rgba(106, 156, 31, 0.733);
}

.topbarLogo{
    position: absolute;
    right: 0;
}

.logoImg{
    height: 45px;
    margin-right: 15px;
}

.nav-menu-container{
    display: flex;
    align-items: center;
}

.navbar-nav{
    max-width: 100%;
    height: 100%;
    display: flex;
    padding-right: 0;
    justify-content: flex-end;
}

.nav-item{
    --item-size: calc(60px * 0.8);
    width: var(--item-size);
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon-button{
    --button-size: calc(var(--item-size) * 0.5);
    width: var(--button-size);
    height: var(--button-size);
    border-radius: 50%;
    background-color: rgb(221, 241, 221);
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: filter 300ms;
    color: black;
}

.icon-button:hover {
    filter: brightness(0.8);
}

.icon-button svg{
    width: 20px;
    height: 20px;
}

.dropDown{
    position: absolute;
    z-index: 3;
    top: 48px;
    width: 300px;
    transform: translateX(45%);
    background-color: rgba(106, 156, 31, 0.733);
    backdrop-filter: blur(5px);
    border-radius: 10px;
    -webkit-box-shadow: 0px 3px 7px 0px rgba(0,0,0,0.36); 
    box-shadow: 0px 3px 7px 0px rgba(0,0,0,0.36);
    padding: 10px;
    overflow: hidden;
    transition: height 500ms ease;
}

.menuHr{
    border: none;
    border-top: solid 1px rgba(128, 128, 128, 0.596);
    margin-top: 5px;
    margin-bottom: 5px;
}

.dropDownItem{
    height: 40px;
    display: flex;
    align-items: center;
    border-radius: 10px;
    transition: background 300ms;
    padding: 5px;
    text-decoration: none;
    color: black;
}

.dropDownItem:hover{
    background-color: rgba(221, 241, 221, 0.404);
}

.menuIcon{
    margin-left: 10px;
}

.archiveTitle{
    font-size: 20px;
    font-weight: 800;
}

.menu{
    width: 100%;
}

.menu-primary-enter{
    position: absolute;
    transform: translateX(-110%);

}

.menu-primary-enter-active{
    transform: translateX(0);
    transition: all 500ms ease;

}

.menu-primary-exit{
    position: absolute;
}

.menu-primary-exit-active{
    transform: translateX(-110%);
    transition: all 500ms ease;
}

.menu-secondary-enter{
    transform: translateX(110%);

}

.menu-secondary-enter-active{
    transform: translateX(0);
    transition: all 500ms ease;

}

.menu-secondary-exit{
}

.menu-secondary-exit-active{
    transform: translateX(110%);
    transition: all 500ms ease;
}