.ShiftPageSection{
    flex: 3;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 15px;

}

.ShiftPageSectionForm{
    width: 100%;
    margin-bottom: 10px;
}

.ShiftPageSectionItemContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(120, 190, 14, 0.418);
    width: 95%;
}

.ShiftPageSectionInput{
    width: 95%;
    padding: 8px;
    border-radius: 3px;
    border: solid 1px rgba(128, 128, 128, 0.39);
    font-size: 14px;
    font-weight: 300;
    cursor: pointer;
}

.ShiftPageSectionList{
    margin-top: 20px;
}

.ShiftPageSectionItem{
    padding: 10px 10px 10px 0;
    width: 85%;
    cursor: pointer;    
}

.shiftPageItemBack{
    margin-left: 10px;
    color:rgba(12, 182, 77, 0.8) !important;
    cursor: pointer;    
}

.shiftPageItemEdit{
    margin-left: 10px;
    color: rgba(100, 148, 237, 0.7) !important;
    cursor: pointer;
}

.shiftPageItemDelete{
    color: rgba(192, 11, 11, 0.7) !important;
    cursor: pointer;    
}

.ShiftPageSectionItem:last-child{
    margin-bottom: 2px;
}

.shiftPageTimeAndItemContainer{
    display: flex;
    align-items: center;
    width: 85%;
}

.shiftPageTime{
    color: black;
    margin-left: 10px;
}

.ShiftPageSectionSubmit{
    visibility: hidden;
}