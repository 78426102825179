.MainMalfunctions{
    flex: 7;
    height: calc(100vh - 50px);
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    position: relative;
}

.malfunctionGroupTitleContainer{
    display: flex;
    z-index: 2;
    justify-content: space-between;
    position: fixed;
    width: 87.2%;
    align-items: center;
    padding: 15px 5px;
    background-color: rgba(106, 156, 31, 0.671);
}

.malfunctionGroupTitle{
    font-size: 22px;
    font-weight: 700;
    color: white;
}

.malfunctionFilterBtn{
    margin-left: 10px;
    background: none;
    border: none;
    cursor: pointer;
    color: white;
    font-size: 20px;
}

.malfunctionGroupNoteContainer{
    margin-top: 90px;

}

.malfunctionGroupNote{
    padding: 5px;
}

.malfunctionGroupExport{
    width: 50px;
    height: 50px;
    position: fixed;
    color: black;
    font-size: 17px;
    font-weight: 800;
    left: 22px;
    bottom: 22px;
    background-color: rgba(106, 156, 31, 0.671);
    border: none;
    border-radius: 50%;
    padding: 17px 10px;
    transition: filter 300ms;
    transition: background 300ms;

}

.malfunctionGroupExport:hover{
    background-color: rgba(106, 156, 31, 0.815);
    cursor: pointer;
}

.malfunctionGroupExportIcon{
    color: rgba(106, 156, 31, 0.671) !important;
    font-size: 20px !important;
    margin: 2px 2px 0 2px;
}

.MalfunctionsSiteContainer{
    margin-top: 5px;
}

.addNewMalfunction{
    position: fixed;
    left: 22px;
    bottom: 22px;
    background-color: rgba(106, 156, 31, 0.671);
    border: none;
    border-radius: 50%;
    padding: 5px;
    transition: filter 300ms;
    transition: background 300ms;
}

.addNewMalfunction:hover{
    background-color: rgba(106, 156, 31, 0.897);
    cursor: pointer;
}

.addNewMalfunctionIcon{
    color: white !important;
    font-size: 40px !important;
}