.farmInformationMainOuter{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 30px 0;
}

.farmInformationMain{
    width: 80%;
    display: flex;
    gap: 50px;
}

.farmInfoRight{
    flex: 1;
    margin-top: 40px;
}

.farmMalfunctionLeft{
    flex: 1;
    margin-top: 40px;
}