.siteNameInput{
    display: flex;
    position: relative;
}

.entryFormInputEmployList{
    width: 100%;
}

.siteNameList{
    padding: 0;
    margin: 0;
    list-style: none;
    z-index: 1;
    position: absolute;
    top: 45px;
    overflow: auto;
    max-height: 190px;
    width: calc(100% - 2px);
    border: 1px solid rgba(0,0,0,.25);
    border-radius: 5px;
    background-color: rgb(239, 241, 239);
    -webkit-box-shadow: 0px 3px 15px -3px rgba(0,0,0,0.6); 
    box-shadow: 0px 3px 15px -3px rgba(0,0,0,0.6);
}

.siteNameListItem{
    padding: 10px;
    font-size: 14px;

}

.siteNameListItem:first-child{
    margin-top: 5px;
}

.siteNameListItem[data-focus="true"]{
    background: rgba(33, 218, 104, 0.527);
}