.managerPlaceMiddleContainer{
    flex:6.5;
    height: calc(100vh - 50px);
}

.managerPlaceMiddleWrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
}

.managerPlaceMiddleTopButtons{
    margin-top: 18px;
    display: flex;
    height: 90px;
    width: 99%;
}

.TopCRUDButton{
    flex:3;
    margin: 0 5px;
    background: none;
    border: none;
    -webkit-box-shadow: 0 10px 15px 0 rgba(0,0,0,0.1);
    box-shadow: 0 10px 15px 0 rgba(0,0,0,0.1);
    font-size: 25px;
    font-weight: 800;
    color: white;
}

.enterCRUDButton{
    background-color: rgba(120, 190, 14, 0.5); 
    backdrop-filter: blur(5px);
    transition: filter 300ms;
}

.editCRUDButton{
    background-color: rgba(100, 148, 237, 0.5);
    backdrop-filter: blur(5px);
    transition: filter 300ms;
}

.deleteCRUDButton{
    background-color: rgba(192, 11, 11, 0.5);
    backdrop-filter: blur(5px);
    transition: filter 300ms;
}

.enterCRUDButton:hover{
    filter: brightness(1.15);
    cursor: pointer;
}

.editCRUDButton:hover{
    filter: brightness(1.15);
    cursor: pointer;
}

.deleteCRUDButton:hover{
    filter: brightness(1.15);
    cursor: pointer;
}

.managerPlaceMiddleComponent{
    height: 100%;
    width: 100%;
}

.managerWelcome{
    margin-top: 20px;
}