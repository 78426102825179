.rightBar{
    flex: 2.5;
}

.rightBarContainer{
    display: flex;
    justify-content: center;
}

.openShiftPage{
    margin: 20px 0;
    padding: 10px;
    border-radius: 3px;
    border: solid 1px rgba(12, 182, 77, 0.8);
    background: none;
    color: rgba(12, 182, 77, 0.705);
    font-size: 16px;
    width: 90%;

}

.openShiftPage:focus{
    background-color: rgba(255, 255, 255, 0.39);
}

.openShiftPage:hover{
    background-color: rgba(12, 182, 77, 0.8);
    border: solid 0.8px rgba(12, 182, 77, 0.8);
    color: rgb(255, 255, 255);
    padding: 10px;
    font-weight: 500;
    font-size: 16px;
    transition: ease-in-out .2s;
    cursor: pointer;
}

.RightBarWrapper{
    display: flex;
    height: calc(100vh - 132px);
    padding: 0 10px 0 5px;
}

.rightBarRight{
    flex: 2;
    display: flex;
    flex-direction: column;
    margin-top: -25px;
    margin-left: 15px;
}

.rightBarLeft{
    flex: 8;
}

.MokedInfo{
    background: none;
    padding-top: 15px;
    margin-top: 2px;
    width: 120px;
    height: 60px;
    border: none;
    border-bottom: 2px inset rgba(12, 182, 77, 0.5);
    border-radius: 0px 0px 30px 30px;
    font-weight: 500;
    font-size: 16px;
}

.MokedInfo:hover{
    cursor: pointer;
    background: #FFFFFF;
    background: -moz-linear-gradient(top, #FFFFFF 0%, #FFFFFF 92%, #71D97D 100%);
    background: -webkit-linear-gradient(top, #FFFFFF 0%, #FFFFFF 92%, #71D97D 100%);
    background: linear-gradient(to bottom, #FFFFFF 0%, #FFFFFF 92%, #71D97D 100%);
    border-bottom: 3px inset rgba(12, 182, 77, 0.911);
}

.mokedInfoTitle{
    font-size: 25px;
    margin-top: 15px;
    padding-bottom: 15px;
}

.mokedInfoList{
    list-style: none;
    margin: 0;
    padding: 0;
    margin-top: 15px;
}

.mokedInfoItem{
    padding: 15px 0;
    margin-bottom: 10px;
    border: none;
    border-bottom: 2px inset rgba(12, 182, 77, 0.3);
}