.ClientEntriesRight{
    flex: 3;
    height: calc(100vh - 50px);
    overflow-y: visible;
    overflow-x: hidden;
    position: relative;
}

.ClientEntriesRightWrapper{
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.ClientEntriesRightTitle{
    margin-bottom: 35px;
}

.downloadEntriesButton{
    width: 100%;
    padding: 10px;
    margin-top: 50px;
    background-color: rgba(12, 182, 77, 0.411);
    border: none;
    color: white;
    font-weight: 800;
    font-size: 16px;
    font-family: 'Arimo', sans-serif;
}

.downloadEntriesButton:hover{
    background-color: rgba(12, 182, 77, 0.589);
    cursor: pointer;
}

.downloadEntriesButtonDisable{
    width: 100%;
    padding: 10px;
    margin-top: 50px;
    background-color: rgba(73, 78, 75, 0.411);
    border: none;
    color: white;
    font-weight: 800;
    font-size: 16px;
}

.downloadEntriesButtonDisable:hover{
    background-color: rgba(73, 78, 75, 0.548);
    cursor: not-allowed;
}

.MataraLogoImgClient{
    position: absolute;
    width: 95%;
    bottom: 20px;
    right: 15px;
}

.clientEntriesWaitingResponse{
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.waitingResponseMessage{
    font-size: 18px;
    text-align: center;
    margin-bottom: 25px;
}

.CircularProgressDesign{
    color:rgba(12, 182, 77, 0.801) !important;
    width: 50px !important;
    height: 50px !important;
}