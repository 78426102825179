.activeEntryList{
    width: 20%;
    margin: 10px 0;
}

.activeEntryItem{
    padding: 10px;
    position: relative;
    margin: 5px;
    min-height: 70px;
    border-radius: 5px;
    -webkit-box-shadow: 0px 3px 7px 0px rgba(0,0,0,0.36); 
    box-shadow: 0px 3px 7px 0px rgba(0,0,0,0.36);
    background-color: rgba(120, 190, 14, 0.384); 
    backdrop-filter: blur(5px);
}

.activeEntryItemTop{
    display: flex;
    justify-content: space-between;
}

.activeSiteName{
    padding: 0;
    margin: 0;
    font-size: 18px;
}

.activeSiteNameSmall {
    padding: 0;
    margin: 0;
    font-size: 16px;
}

.burgerIcon{
    color:green;
}

.burgerIcon:hover{
    color:rgb(129, 207, 51);
    font-size: 22px !important;
    cursor: pointer;
}

.activeEntryItemBottom{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.employLengthIcon{
    color: green;
}

.activeEntryItemBottomRight{
    display: flex;
    position: absolute;
    bottom: 5px;
}

.employLengthText{
    color: green;
    font-size: 15px;
    font-weight: 600;
    margin: 0;
}

.lastUpdated{
    font-size: 14px;
    font-weight: 600;
    position: absolute;
    bottom: 10px;
    left: 10px;
    margin: 0;
}