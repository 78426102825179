.checkListEventRight{
    height: 100%;
    width: 50%;
}

.overFlowCheckList{
    overflow-y: scroll;
}

.checkListHover:hover{
    color: red;
    cursor: pointer;
}