.closeScoutTypeLayoutContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin: 20px;  
}
  
  .closeScoutTypeLayoutBsckButton {
    width: 60px;
    height: 60px;
    font-weight: bold;
    font-size: 20px;
    border-radius: 50%;
    border: none;
    background-color: rgba(106, 156, 31, 0.733);  
    color: white;
    align-self: start;
  }

  .closeScoutInfoWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 100%;
  }
  .closeScoutLabels {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    gap: 10px;
    width: 100%;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(106, 156, 31, 0.733);
  }
  
  .closeScoutLabelContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .closeScoutSmallLabelContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 35%;
  }

  .closeScoutSmallLabelEditDelete {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 25%;
    border: 1px solid rgba(100, 148, 237, 0.7);
    padding: 10px;
  }

  .closeScoutSmallLabelEditDelete:hover {
    background-color: rgba(100, 148, 237, 0.7);
    color: white;
    transition: ease-in-out .2s;
    cursor: pointer;
  }

  .editContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 100%;
  }

  .FiltrerScoutsContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
  }

  .scoutFilterBtn {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 20px;
  }

  .scoutFilterDatePickerContainer {
    position: relative;
    transform: translateY(-100px);
  }

  .closeScoutEditContainer {
    position: absolute;
    display: flex;
    top: 0;
    right: 0;
    z-index: 200;
    height: 100vh;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    align-items: center;
    justify-content: center;
  }

  .closeScoutEditInner {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 20px;
    border: 1px solid rgba(106, 156, 31, 0.733);
    background-color: white;
    width: 30%;
  }

  .closeScoutEditCloseButton{
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: rgba(106, 156, 31, 0.733);
  }

  .closeScoutEditFields {
    display: flex;
    flex-direction: column;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 40px 20px;
    width: 80%;
  }

  .closeScoutEditButtonContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 100%;
  }
  
  .closeScoutEditButton {
    width: 45%;
    height: 60px;
    font-weight: bold;
    font-size: 20px;
    border-radius: 10px;
    border: none;
    background-color: rgba(106, 156, 31, 0.733);  
    color: white;
  }