.ClientEntriesInformationInstructions{
    padding: 20px;
    padding-top: 10px;
}

.ClientInstructions{
    padding: 15px;
    font-size: 18px;
}



.markedWord{
    background-color: rgba(12, 182, 77, 0.438);
}