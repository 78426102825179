.managerPlaceLeftContainer{
    flex:2;
    height: calc(100vh - 50px);
    overflow-y: visible;
    overflow-x: hidden;
}

.managerPlaceLeftWrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.mokedWorkersListLeft{
    width: 100%;
    margin: 0;
    padding: 0;
}

.listItemManagerPlaceLeftWrapper{
    display: flex;
    justify-content: flex-start;
    border-bottom: 2px inset rgba(12, 182, 77, 0.3);
}

.listItemManagerPlaceLeft{
    font-size: 18px;
    padding: 15px 0;
}

.isAhmashManagerPlace{
    font-size: 18px;
    padding: 15px 0;
    padding-right: 5px;
}