.MultiChoiseInput {
    margin: 0 auto;
    max-width: 100%;
    position: relative;
}

.MultiChoiseFormInput {
    width: 95%;
    height: 20px;
    font-size: 15px;
    padding: 10px;
    border: none;
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    position: relative;
    margin: 15px auto 0;
}

  @-webkit-keyframes fadeInDown {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, -50%, 0) scaleY(0.3);
      transform: translate3d(0, -50%, 0) scaleY(0.3);
    }
    100% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  
  @keyframes fadeInDown {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, -50%, 0) scaleY(0.3);
      transform: translate3d(0, -50%, 0) scaleY(0.3);
    }
    100% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }

.MultiChoiseList{
    padding: 0;
    margin: 0;
    list-style: none;
    z-index: 1;
    position: absolute;
    top: 60px;
    overflow: auto;
    max-height: 190px;
    width: calc(100% - 2px);
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 10px;
    background-color: rgb(244, 245, 244);
    -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.MultiChoiseListItem{
    padding: 10px;
    font-size: 15px;

}

.MultiChoiseListItem:first-child{
    margin-top: 5px;
}

.MultiChoiseListItem:last-child{
    margin-bottom: 5px;
}

.MultiChoiseListItem[data-focus="true"]{
    background: rgba(106, 156, 31, 0.3); 
}