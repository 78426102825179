.siteMalfunctionContainer{
    padding: 20px 5px;
    font-size: 15px;
}

.siteMalfunctionTitle{
    font-size: 20px;
    font-weight: 700;
    padding: 15px 10px 15px 0;
    background-color: rgba(120, 190, 14, 0.192);
    text-align: right;
}

.malfunctionTitles{
    padding: 0;
    margin: 0;
    margin-bottom: 5px;
    list-style: none;
    display: flex;
    width: 100%;
    font-weight: 800;
    border-bottom: solid 1px rgba(120, 190, 14, 0.308);
    text-align: right;
}

.sideTitle{
    font-size: 17px;
}

.malfunction:nth-child(odd){
    position: relative;
    padding: 0;
    margin: 0;
    margin-bottom: 5px;
    list-style: none;
    display: flex;
    width: 100%;
    font-weight: 800;
    background-color: rgba(120, 190, 14, 0.05);
}

.malfunction:nth-child(even){
    position: relative;
    padding: 0;
    margin: 0;
    margin-bottom: 5px;
    list-style: none;
    display: flex;
    width: 100%;
    font-weight: 800;
}

.malfunctionItems{
    flex: 4;
    padding: 15px 0;
    border-left: rgba(120, 190, 14, 0.308) 1px solid;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.malfunctionItemsMedium{
    flex: 3;
    padding: 15px 0;
    border-left: rgba(120, 190, 14, 0.308) 1px solid;
    display: flex;
    justify-content: center;
}

.malfunctionItemsSmall{
    flex: 2;
    padding: 15px 0;
    border-left: rgba(120, 190, 14, 0.308) 1px solid;
    display: flex;
    justify-content: center;
}

.malfunctionItemsSmallTwo{
    flex: 2;
    padding: 15px 0;
    display: flex;
    justify-content: center;
}

.malfunctionItemsBtn{
    flex: 1;
    padding: 15px 0;
}

.malfunctionEdit{
    position: absolute;
    left: 100px;
    top: 0;
    background: none;
    border: 2px solid rgba(100, 148, 237, 0.7);
    font-weight: 700;
    font-size: 15px;
    padding: 13px;
}

.malfunctionDone{
    position: absolute;
    left: 30px;
    top: 0;
    background: none;
    border: 2px solid rgba(120, 190, 14, 0.596);
    font-weight: 700;
    font-size: 15px;
    padding: 13px;
}

.malfunctionEdit:hover{
    background-color: rgba(100, 148, 237, 0.7);
    color: white;
    transition: ease-in-out .2s;
    cursor: pointer;
    padding: 11px;
    top: 2px;
}

.malfunctionDone:hover{
    background-color: rgba(120, 190, 14, 0.596);
    color: white;
    transition: ease-in-out .2s;
    cursor: pointer;
    padding: 11px;
    top: 2px;
}

.tableContainer{
    width: 100%;
}

.downloadBtn{
    position: absolute;
    left: 0;
    font-size: 50px;
    padding: 50px;
    color: red;
}