.CheckListContainer{
    width: 100%;
    height: 100%;
    display: flex;
}

.CheckListCannabisContainer{
    flex: 2;
    margin: 20px;
}

.CheckListSolarContainer{
    flex: 2;
    margin: 20px;

}

.primaryList{
    font-size: 20px;
}

.secondaryList{
    list-style-type: lower-alpha;
}

.checkListItem{
    padding: 10px;
}