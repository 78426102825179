.ClientEntriesRightRight{
    flex: 3;
    height: calc(100vh - 50px);
    overflow-y: visible;
    overflow-x: hidden;
    position: relative;
}

.ClientEntriesRightWrapper{
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.chosenDriverName{
    font-weight: 600;
    margin-bottom: 5px;
}

.finishedTransformsInformationContainer{
    margin-right: -10px;
}