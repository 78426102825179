.leftBar{
    flex: 3.5;
    height: calc(100vh - 50px);
    overflow-y: scroll;
    overflow-x: hidden;
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    background-color: rgb(221, 241, 221);
    border-radius: 5px;
}

::-webkit-scrollbar-thumb {
    background-color: rgb(108, 173, 9);
    border-radius: 0 0 5px 5px;
}

.leftBarWrapper{
    padding: 20px 20px 20px 5px;
    align-items: center;    
}

.leftBarTop{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.exitAlert{
    margin: 10px 0;
}

.exitIcon{
    margin: 0;
    padding: 0 0 0 10px;
}

.siteNameTitle{
    font-size: 25px;
}

.optionSiteInfo{
    background: none;
    padding: 15px 25px 5px;
    width: 150px;
    height: 60px;
    border: none;
    border-bottom: 3px inset rgba(12, 182, 77, 0.5);
    border-radius: 0px 0px 30px 30px;
    font-weight: 600;
    font-size: 16px;
    margin-left: 10px;
}

.optionFunctionInfo{
    background: none;
    padding: 15px 25px 5px;
    height: 60px;
    border: none;
    border-bottom: 3px inset rgba(12, 182, 77, 0.5);
    border-radius: 0px 0px 30px 30px;
    font-weight: 600;
    font-size: 16px;
}

.clearAllSite{
    background: none;
    padding: 15px 25px 5px;
    width: 200px;
    height: 60px;
    border: none;
    border-bottom: 3px inset rgba(12, 182, 77, 0.5);
    border-radius: 30px;
    font-weight: 600;
    font-size: 16px;
}

.optionFunctionInfo:hover{
    cursor: pointer;
    background: #FFFFFF;
    background: -moz-linear-gradient(top, #FFFFFF 0%, #FFFFFF 92%, #71D97D 100%);
    background: -webkit-linear-gradient(top, #FFFFFF 0%, #FFFFFF 92%, #71D97D 100%);
    background: linear-gradient(to bottom, #FFFFFF 0%, #FFFFFF 92%, #71D97D 100%);
    border-bottom: 3px inset rgba(12, 182, 77, 0.911);
}


.optionSiteInfo:hover{
    cursor: pointer;
    background: #FFFFFF;
    background: -moz-linear-gradient(top, #FFFFFF 0%, #FFFFFF 92%, #71D97D 100%);
    background: -webkit-linear-gradient(top, #FFFFFF 0%, #FFFFFF 92%, #71D97D 100%);
    background: linear-gradient(to bottom, #FFFFFF 0%, #FFFFFF 92%, #71D97D 100%);
    border-bottom: 3px inset rgba(12, 182, 77, 0.911);
}

.clearAllSite:hover{
    cursor: pointer;
    background: #FFFFFF;
    background: -moz-linear-gradient(top, #FFFFFF 0%, #FFFFFF 92%, #D91A1A 100%);
    background: -webkit-linear-gradient(top, #FFFFFF 0%, #FFFFFF 92%, #D91A1A 100%);
    background: linear-gradient(to bottom, #FFFFFF 0%, #FFFFFF 92%, #D91A1A 100%);
    border-bottom: 3px inset rgba(255, 0, 0, 0.726);
}

.approveTitle{
    text-align: right;
    font-size: 23px;
    padding-bottom: 5px;
    margin: 0;
}

.approveMessage{
    text-align: right;
    font-weight: 500;
    margin: 0;
}

.react-confirm-alert-overlay{
    background: rgba(255, 255, 255, 0.6) !important;
}

.react-confirm-alert-button-group {
    justify-content: flex-end !important;
    margin: 0;
}
  
  .react-confirm-alert-button-group > button {
    background:rgb(108, 173, 9) !important;
    margin-top: 10px !important;
    margin-bottom: -10px !important;
    font-size: 14px !important;
}
  

.leftBarData{
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    padding-top: 20px;
}