.creatorNameList{
    padding: 0;
    margin: 0;
    list-style: none;
    z-index: 1;
    position: absolute;
    top: 45px;
    overflow: auto;
    max-height: 115px;
    width: calc(100% - 2px);
    border: 1px solid rgba(0,0,0,.25);
    border-radius: 5px;
    background-color: rgb(239, 241, 239);
    -webkit-box-shadow: 0px 3px 15px -3px rgba(0,0,0,0.6); 
    box-shadow: 0px 3px 15px -3px rgba(0,0,0,0.6);
}