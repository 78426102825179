.inputSectionPopUpMessages{
    width: 100%;
}

.popupFormContainerPermission{
    min-height: 100px;
}

.popupMainTitle{
    text-align: center;
    margin-bottom: 20px;
}