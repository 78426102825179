.FinishedTransformsFormFile{
    position: fixed;
    z-index: 4;
    top: 0;
    right: 0;
    width: 100%;
    height: calc(100vh);
    overflow-y: scroll;
    overflow-x: hidden;
    backdrop-filter: blur(5px);
    background-color: rgba(255, 255, 255, 0.466);
    display: flex;
    justify-content: center;
    align-items: center;
}

.FinishedTransformsFormFileInner{
    position: relative;
    padding: 20px;
    margin-right: 30%;
    width: 65%;
    max-height: 80%;
    overflow-y: scroll;
    overflow-x: hidden;
    backdrop-filter: blur(5px);
    background-color: rgba(255, 255, 255, 0.685);  
    border-radius: 5px;
    -webkit-box-shadow: 0 10px 30px 0 rgba(0,0,0,0.3);
    box-shadow: 0 10px 30px 0 rgba(0,0,0,0.3);
}

.endTransformsInnerDownload{
    top: 50px;
    left: 15px;
    padding: 20px;
    color: rgb(81, 126, 14);
    background-color: rgba(120, 190, 14, 0.384);
    width: 200px;
    border: 1px solid rgba(120, 190, 14, 0.418);
    transition: filter 300ms;
}

.endTransformsInnerDownload:hover{
    filter: brightness(1.2);
}

.finishedTransformIcon{
    margin-bottom: -2px;
    padding-right: 10px;
}

.guardsInformationContainer{
    display: flex;
    align-items: center;
}

.guardsInfoName{
    margin: 15px -2px 5px 15px;
}

.guardsInfoHour{
    margin-top: 10px;
    padding-right: 10px;
    border-right: 1px solid grey;
}

.endTransformsBottom{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.FinishedTransformsSideNoteTitle{
    margin-bottom: 10px;
}