.TransformsFormsLeftContainer{
    padding: 20px;
}

.TransformsFormsLeftTop{
    position: relative;
    margin-bottom: 33px;
}

.deleteBtn{
    background: none;
    position: absolute;
    padding-top: 15px;
    top: 0;
    left: 0;
    margin-top: -20px;
    margin-left: -20px;
    width: 200px;
    height: 60px;
    border: none;
    border-bottom: 3px inset rgba(12, 182, 77, 0.5);
    border-radius: 30px;
    font-weight: 600;
    font-size: 16px;
}

.deleteBtn:hover{
    cursor: pointer;
    background: #FFFFFF;
    background: -moz-linear-gradient(top, #FFFFFF 0%, #FFFFFF 92%, #D91A1A 100%);
    background: -webkit-linear-gradient(top, #FFFFFF 0%, #FFFFFF 92%, #D91A1A 100%);
    background: linear-gradient(to bottom, #FFFFFF 0%, #FFFFFF 92%, #D91A1A 100%);
    border-bottom: 3px inset rgba(255, 0, 0, 0.726);
}

.transformsHrContainer{
    display: flex;
    justify-content: center;
    align-content: center;
}

.transformsHr{
    margin-top: 15px;
    margin-bottom: -10px;
    width: 30%;
    align-self: center;
    border: 1px solid rgba(0, 128, 0, 0.363);
}

.TransformsInformation:first-child{
    margin-bottom: 5px;
}

.guardsNameInformation{
    font-size: 16px;
    font-weight: 600;
    padding: 8px 0 8px 0;
}

.guardsNameInformation:first-child{
    margin-top: 5px;
}

.dateTransforms{
    font-weight: 600;
    font-size: 20px;
}

.transformDataList{
    padding: 0 0 0 0;
    margin-top: 15px;
    list-style: none;
    width: 100%;
    position: relative;
}

.transformsDataLine:nth-child(odd){
    width: 100%;
    display: flex;
    align-items: center;
    padding: 15px 15px 15px 0;
    margin-bottom: 3px;
    font-size: 15px;
    font-weight: 400;
    background-color: rgba(18, 199, 18, 0.15);  
    border: none;
    border-bottom: 2px inset rgba(12, 182, 77, 0.5);
}

.transformsDataLine:nth-child(even){
    width: 100%;
    display: flex;
    align-items: center;
    padding: 15px 15px 15px 0;
    margin-bottom: 3px;
    font-size: 15px;
    font-weight: 400;
    background-color: rgba(221, 221, 221, 0.39);
    border: none;
    border-bottom: 2px inset rgba(12, 182, 77, 0.5);
}

.transformDataDateItem{
    margin-left: 15px;
    padding-left: 5px;
    border-left: 1px solid grey;
}

.transformDataItem:hover{
    color:cornflowerblue;
    font-size: 17px;
    cursor: pointer;
}

.transformsDataForm{
    display: flex;
    width: 100%;
    position: relative;
    margin-top: 5px;
    margin-right: 9px;
}

.transformsDataInput{
    width: 100%;
    padding: 8px;
    border-radius: 3px;
    border: none;
    border-bottom: solid 1px rgba(128, 128, 128, 0.39);
    font-size: 14px;
    font-weight: 300;
    margin-top: 10px;
}

.transformsDataInput:focus{
    outline: none;
    padding: 10px;
    border-bottom: 1px solid rgba(39, 223, 14, 0.568);
    transition: border .2s 
}

.transformsDataBtn{
    position: absolute;
    left: 0;
    height: 100%;
    padding: 0 15px;
    background: none;
    border: none;
    font-size: 16px;
    font-weight: 800;
    color: green;
}

.transformsDataBtn:hover{
    cursor: pointer;
    background-color: rgba(163, 163, 163, 0.247);
    font-weight: 600;
}

.TransformsFormsLeftFooter{
    margin-top: 15px;
    width: 100%;
}

.endTransformBtn{
    width: 100%;
    margin-right: 9px;
    padding: 10px;
    border-radius: 3px;
    border: solid 1px rgba(12, 182, 77, 0.5);
    background: none;
    color: rgba(12, 182, 77, 0.705);
    font-size: 16px;
}

.endTransformBtn:hover{
    background-color: rgba(12, 182, 77, 0.774);
    border: solid 0.5px rgba(12, 182, 77, 0.5);
    color: white;
    padding: 10px;
    font-weight: 500;
    font-size: 16px;
    transition: ease-in-out .2s;
    cursor: pointer;
}