.msg{
    width:100%;
    height:auto;
    display:block;
    overflow:hidden;
}

.bubble{
    position: relative;
    float:left;
    max-width: 75%;
    width: auto;    
    height: auto;
    display: block;
    background: #ebebeb;
    border-radius: 5px;
    margin: 10px 0 3px 25px;   
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2); 

}   

  .alt{
    margin: 10px 25px 3px 0px;
    background-color: #DCF8C6; 
    float:right;
  }

  .follow{

}

  .altfollow{

  }

  .txt{
    padding: 8px 0 8px 0;
  }

  .name{
    font-weight: 600;
    font-size: 14px;
    padding: 0 15px 0 0 ;
    float: right;
    color: #3498db;
  }

  span{
    font-weight: normal;
    color: #b3b3b3;
  }

  .message{
    font-size: 14px;
    font-weight:500;
    padding: 0 15px 0 15px;
    color: #2b2b2b;
    display: flex;
    width: 75%;

  }

  .timestamp{
    font-size: 13px;
    padding: 0 0 0 15px;
    float: left;
    color: #999
  }

  .bubble-arrow {
    position: absolute;
    float:left;
    left: 2px;
    top: -1px;
  }

  .bubble-arrow-alt{
    position: absolute;
    top: 0px;
    left: auto;
    right: -13px;
    float:right;
  }

  .bubble-arrow:after {
    content: "";
    position: absolute;
    border-top: 15px solid #ebebeb;
    border-left: 15px solid transparent;           
    border-radius: 4px 0 0 0px;
    width: 0;
    height: 0;
    z-index: 2;
}

  .bubble-arrow-alt:after {
    content: "";
    position: absolute;
    border-left: 15px solid transparent;           
    border-radius: 4px 0 0 0px;
    width: 0;
    height: 0;
    border-top: 15px solid #DCF8C6;
    transform: scaleX(-1);
    z-index: 2;
}

