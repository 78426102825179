
.datePickerItem{
    padding: 10px;
    border: none;
    border-bottom: 2px inset rgba(12, 182, 77, 0.3);
}

.datePickerItem:focus{
    background-color: rgba(12, 182, 77, 0.075);
}


.startDatePicker{
    padding: 20px;
}

.endDatePicker{
    padding: 20px;
}

.startDatePickerTitle{
    font-size: 16px;
    margin-bottom: 10px;
}