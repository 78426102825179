.shiftChatContainer{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-end;
    margin-bottom: 20px;
}

.shiftChatComponent{
    height: 100%;
    margin-bottom: 20px;
    overflow: auto;
}

.shiftChatForm{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.shiftChatTextArea{
    padding: 10px;
    padding-left: 20%;
    height: 59px;
    font-size: 15px;
    resize: none;
    border-radius: 5px;
    -webkit-box-shadow: 0px 3px 7px 0px rgba(0,0,0,0.36); 
    box-shadow: 0px 3px 7px 0px rgba(0,0,0,0.36);
    backdrop-filter: blur(5px);
}

.shiftChatSubmit{
    position: absolute;
    margin: 0;
    padding: 0;
    border: none;
    height: 80px;
    width: 20%;
    left: 0;
    bottom: 1px;
    background-color: rgba(120, 190, 14, 0.384); 
    font-size: 18px;
    border-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    cursor: pointer;
}