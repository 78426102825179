.mokedScansContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
}

.mokedScansTopContainer{
    display: flex;
    z-index: 2;
    justify-content: space-between;
    align-items: center;
    padding: 15px 5px;
    background-color: rgba(106, 156, 31, 0.671);
}

.mokedScansSelectContainer{
    width: 30%;
    display: flex;
    justify-content: space-evenly;
}

.mokedScansChooseDay{
    font-size: 20px;
    font-weight: 700;
    color: white;
    cursor: pointer;
}

.mokedScansSelect{
    padding: 15px 5px;
    margin: 0 5px;
    border-radius: 5px;
    font-size: 15px;
    width: 30%;
}