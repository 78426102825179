.mokedWorker{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mokedWorkerWrapper{
    display: flex;
    padding: 20px;
    width: 100%;
    height: 50%;
    -webkit-box-shadow: 0 10px 30px 0 rgba(0,0,0,0.3);
    box-shadow: 0 10px 30px 0 rgba(0,0,0,0.3);
    margin: 13px;
}

.mokedWorkerRight{
    height: 100%;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mokedWorkerLeft{
    width: 50%;
    height: 100%;
    border-right: 2px inset rgba(12, 182, 77, 0.3);
    padding-right: 20px;
}

.mokedWorkerForm{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 100%;
    width: 100%;
}

.mokedWorkerInput{
    width: 90%;
    padding: 20px 0;
    text-align: center;
    margin: 20px 0;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 400;
    background-color: rgba(214, 214, 214, 0.212);
}

.mokedWorkerInput::placeholder{
    font-size: 16px;
    font-weight: 400;
}

.BottomFormMokedWorkersAhmash{
    display: flex;
    justify-content: center;
}

.ahmashTitle{
    padding-left: 10px;
}

.BottomFormMokedWorkers{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}
.BottomFormMokedWorkersButton{
    width: 90%;
    margin-top: 20px;
    background-color: rgba(120, 190, 14, 0.5);
    border: none;
    padding: 20px;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 600;
    color: white;
    -webkit-box-shadow: 0 10px 10px 0 rgba(0,0,0,0.1);
    box-shadow: 0 10px 10px 0 rgba(0,0,0,0.1);
    transition: filter 300ms;
}

.BottomFormMokedWorkersButton:hover{
    filter: brightness(1.1);
}

.EditMokedWorkerCRUDTop{
    width: 100%;
    display: flex;
    justify-content: center;
}

.editMokedWorkerInputEmployList{
    width: 95%;
    margin-right: 5px;
}

.enterCRUDButton{
    background-color: rgba(120, 190, 14, 0.5); 
    backdrop-filter: blur(5px);
    transition: filter 300ms;
}

.editCRUDButton{
    background-color: rgba(100, 148, 237, 0.5);
    backdrop-filter: blur(5px);
    transition: filter 300ms;
}

.deleteCRUDButton{
    background-color: rgba(192, 11, 11, 0.5);
    backdrop-filter: blur(5px);
    transition: filter 300ms;
}

.enterCRUDButton:hover{
    filter: brightness(1.15);
    cursor: pointer;
}

.editCRUDButton:hover{
    filter: brightness(1.15);
    cursor: pointer;
}

.deleteCRUDButton:hover{
    filter: brightness(1.15);
    cursor: pointer;
}