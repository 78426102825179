.MokedScansMainContainer{
    margin: 20px ;
    width: 100%;
}

.MokedScansMain{
    display: flex;
    justify-content: space-around;
}

.MokedScansMainSiteName{
    padding-top: 20px;
    width: 100%;
    padding-bottom: 5px;
    border-bottom: solid 1px rgba(120, 190, 14, 0.308);
}