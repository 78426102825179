.alarmMessage{
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: calc(100vh - 50px);
    background-color: rgba(255, 255, 255, 0.466);
    display: flex;
    justify-content: center;
    align-items: center;
}

.alarmMessageInner{
    position: relative;
    display: flex;
    padding: 20px;
    margin-right: 30%;
    width: 40%;
    height: 10%;
    background-color: rgba(11, 184, 63, 0.555);  
    backdrop-filter: blur(20px);
    border-radius: 5px;
    -webkit-box-shadow: 0 10px 30px 0 rgba(0,0,0,0.3);
    box-shadow: 0 10px 30px 0 rgba(0,0,0,0.3);
    justify-content: center;
    align-items: center;
}

.alarmMessageInnerEditAlarm{
    background-color: rgba(100, 148, 237, 0.555);  
}

.alarmMessageInnerDeleteAlarm{
    background-color: rgba(192, 11, 11, 0.555);  
}

.alarmMessageTitle{
    color: white;
    font-size: 40px;
}
