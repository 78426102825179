.EntryInformationList{
    padding: 20px;
    margin: 0;
    list-style: none;
}

.EntryInformationItem{
    padding: 15px 0 15px 15px;
    border-bottom: 2px inset rgba(12, 182, 77, 0.3);

}