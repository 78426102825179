.malfunctionWindow{
    position: fixed;
    z-index: 4;
    top: 0;
    right: 0;
    width: 100%;
    height: calc(100vh);
    backdrop-filter: blur(5px);
    background-color: rgba(255, 255, 255, 0.466);
    display: flex;
    justify-content: center;
    align-items: center;
}

.malfunctionWindowInner{
    display: flex;
    position: relative;
    padding: 20px;
    margin-right: 13%;
    width: 40%;
    max-height: 80%;
    min-height: 27%;
    overflow-y: scroll;
    overflow-x: hidden;
    backdrop-filter: blur(5px);
    background-color: rgba(255, 255, 255, 0.685);  
    border-radius: 5px;
    -webkit-box-shadow: 0 10px 30px 0 rgba(0,0,0,0.3);
    box-shadow: 0 10px 30px 0 rgba(0,0,0,0.3);
    display: flex;
    flex-direction: column;
}

.closeButton{
    position: absolute;
    top: 5px;
    right: 0;
    background: none;
    border: none;
}

.closeButtonIcon{
    color: rgba(106, 156, 31, 0.897) !important;
}

.closeButtonIcon:hover{
    cursor: pointer;
    color: rgba(106, 156, 31, 0.952) !important;
}

.malfunctionWindowFormInput{
    display: flex;
    flex-direction: row;
    width: 100%;
    
}

.malfunctionWindowName{
    margin: 20px 5px;
    flex: 5;
    display: flex;
    flex-direction: column;
}

.malfunctionWindowComment{
    margin: 20px 5px;
    flex: 5;
}

.editMalfunctionIcon{
    color: white !important;
    font-size: 33px !important;
    padding: 3px;
}

.removeMalfunctionIcon{
    color: white !important;
    font-size: 33px !important;
    padding: 3px;
}

.editMalfunction{
    position: fixed;
    left: 22px;
    bottom: 22px;
    background-color: rgba(100, 148, 237, 0.7);
    border: none;
    border-radius: 50%;
    padding: 5px;
    transition: filter 300ms;
    transition: background 300ms;
}

.removeMalfunction{
    position: fixed;
    left: 80px;
    bottom: 22px;
    background-color: rgba(192, 11, 11, 0.7);
    border: none;
    border-radius: 50%;
    padding: 5px;
    transition: filter 300ms;
    transition: background 300ms;
}

.editMalfunction:hover{
    background-color: rgba(100, 148, 237, 0.85);
    cursor: pointer;
}

.removeMalfunction:hover{
    background-color: rgba(192, 11, 11, 0.85);
    cursor: pointer;
}

.siteNameInputSideNote{
    display: flex;
    flex-direction: column;
    width: 80%;
    margin-top: -20px;
    position: relative;
    align-items: flex-start;
}

.malfunctionAddSideNoteForm{
    width: 100%;
}

.malfunctionInputSideNote{
    width: 100%;
    margin-right: 5px;
}

.malfunctionAddSideNote{
    background: none;
    border: none;
}

.malfunctionAddSideNote:hover{
    font-weight: 700;
    cursor: pointer;
}

.malfunctionListSideNote{
    display: flex;
    width: 100%;
}

.malfunctionListList{
    margin-right: 25px;
}

.colorPickerMalfunction{
    display: flex;
    align-items: center;
}

.malfunctionLabel{
    font-size: 15px;
    margin-right: 5px;
    margin-left: 10px;
}
