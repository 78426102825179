.siteNameTitle{
    margin: 20px 0;
}

.EntryHistoryListTitles{
    padding: 0;
    margin: 0;
    margin-bottom: 5px;
    list-style: none;
    display: flex;
    width: 100%;
    position: relative;
    font-weight: 800;
}

.EntryHistoryItemHours{
    margin-right: 20px;
}

.EntryHistoryApproves{
    margin-right: -15px;
}

.EntryHistoryLists{
    padding: 0 15px 0 0;
    margin: 0;
    list-style: none;
    display: flex;
    width: 100%;
    border-bottom: 2px inset rgba(12, 182, 77, 0.3);
    position: relative;
    overflow-y: visible;
}

.EntryHistoryListActives{
    padding: 0 15px 0 0;
    margin: 0;
    list-style: none;
    display: flex;
    width: 100%;
    border-bottom: 2px inset rgba(12, 182, 77, 0.438);
    position: relative;
    background-color: rgba(12, 182, 77, 0.116);
}

.EntryHistoryItems{
    width: 15%;
    padding: 15px 0;
}

.EntryHistoryItemNames:hover{
    color:cornflowerblue;
    font-size: 17px;
    cursor: pointer;
}