.ClientEntriesLeft{
    flex: 7;
    height: calc(100vh - 50px);
    overflow-y: scroll;
    overflow-x: hidden;
}

.ClientEntriesLeftWrapper{
    display: flex;
    flex-direction: column;
    padding: 20px;
}