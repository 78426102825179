.employList{
    display: flex;
    align-items: center;
    position: relative;
}

.employListItem{
    padding: 0 5px 0 0;
    margin: 0;
    list-style: none;
}

.employItem{
    width: fit-content;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-size: 13px;
    font-weight: 600;
}

.employItem:hover{
    font-size: 15px;
    color: red;
    cursor:pointer;
    transition: ease-in-out .1s;
    cursor: pointer;
}

.employItem:first-child{
    margin-top: 5px;
}

.employItemIcon{
    font-size: 12px !important;
    color: green;
    background: none;
}

.employItemIcon:focus{
    outline: 1px solid rgba(39, 223, 14, 0.568);
}
.entryFormInputEmployList{
    width: 100%;
}

.personAddButton{
    position: absolute;
    left: 1px;
    color: green;
    background: none;
    border: none;
}

.personAddIcon:hover{
    font-size: 22px !important;
    cursor: pointer;
}

.personAddIcon:active{
    color: lawngreen;
    font-size: 25px !important;
}
