.entryFormContainer{
    width: 100%;
    height:inherit;
    border-radius: 5px;
    -webkit-box-shadow: 0px 3px 7px 0px rgba(0,0,0,0.36); 
    box-shadow: 0px 3px 7px 0px rgba(0,0,0,0.36);
    background-color: rgba(120, 190, 14, 0.384); 
    backdrop-filter: blur(5px);
    margin-bottom: 15px;
}

.entryFormWrapper{
    padding: 10px;
}

.entryFormTop{
    width: 100%;
    height: 25px;
    margin-bottom: 40px;
}

.messagePopUp{
    margin: 5px 0;
}

.entryFormTopStatus{
    display: flex;
    align-items: center;
}

.formStatusIconEnter{
    color: green;
    font-size: 30px !important;  
}

.formStatusIconEdit{
    color:cornflowerblue;
    font-size: 30px !important;   
}

.entryFormBottom{
    width: 100%;
    display: flex;
}

.entryFormBottomRight{
    flex: 3;
}

.entryFormBottomRightButtons{
    display: flex;
    flex-direction: column;
    width: 80%;
}

.entryFormBottomRightButtonsBack{
    margin-top: 20px;
    padding: 15px;
    border-radius: 5px;
    font-size: 17px;
    font-weight: 500;
    cursor: pointer;
    background-color: rgba(100, 148, 237, 0.5);
}

.entryFormBottomRightButtonsDelete{
    margin-top: 20px;
    padding: 15px;
    border-radius: 5px;
    font-size: 17px;
    font-weight: 500;
    cursor: pointer;
    background-color: rgba(192, 11, 11, 0.5);
}

.entryFormBottomRightButtonsBack:hover{
    background-color: rgba(100, 148, 237, 0.7);
    transition: background-color .2s;
}

.entryFormBottomRightButtonsDelete:hover{
    background-color: rgba(192, 11, 11, 0.7);
    transition: background-color .2s;
}


.entryFormBottomLeft{
    flex: 9;
}

.entryForm{
    display: flex;
    flex-direction: column;
}

.entryFormLabel:not(:first-child){
    margin-top: 10px;
}

.entryFormLabel{
    font-size: 14px;
    margin-bottom: 5px;
}

.approvedLabel{
    display: flex;
    align-items:center;
    position: relative;
}

.emptyField{
    padding-top: 5px; 
    font-size: 13px;
    font-weight: 400;
    color: red;
}

.approveBtn{

}

.approvedIcon{
    font-size: 20px !important;
    color: green;
}

.approvedIcon:hover{
    cursor: pointer;
    font-size: 18px !important;
}

.isApprovedIcon{
    position: absolute;
    left: 50px;
    background: none;
    border: none;
}

.notApprovedIcon{
    position: absolute;
    left: 12px;
    background: none;
    border: none;
}

.notApprovedIcon:focus{
    outline: 1px solid rgba(39, 223, 14, 0.568);
}

.isApprovedIcon:focus{
    outline: 1px solid rgba(39, 223, 14, 0.568);
}

.entryFormInput{
    padding: 8px;
    border-radius: 3px;
    border: solid 1px rgba(128, 128, 128, 0.39);
    font-size: 14px;
    font-weight: 300;
}

.entryFormInput:focus{
    outline: none;
    padding: 10px;
    border: 1px solid rgba(39, 223, 14, 0.568);
    transition: border .2s 
}

.entryFormBtn{
    margin-top: 10px;
    padding: 10px;
    border-radius: 3px;
    border: solid 1px rgba(12, 182, 77, 0.8);
    background: none;
    color: rgba(12, 182, 77, 0.705);
    font-size: 16px;
}

.entryFormBtn:focus{
    background-color: rgba(255, 255, 255, 0.39);
}

.entryFormBtn:hover{
    background-color: rgba(12, 182, 77, 0.8);
    border: solid 0.8px rgba(12, 182, 77, 0.8);
    color: rgb(255, 255, 255);
    padding: 10px;
    font-weight: 500;
    font-size: 16px;
    transition: ease-in-out .2s;
    cursor: pointer;
}



.editEntryFormBtn{
    margin-top: 10px;
    padding: 10px;
    border-radius: 3px;
    border: solid 1px rgba(100, 148, 237, 0.7);
    background-color: rgba(255, 255, 255, 0.541);
    color: rgba(100, 148, 237, 0.7);
    font-size: 14px;
}

.editEntryFormBtn:focus{
    background-color: rgba(255, 255, 255, 0.678);
}

.editEntryFormBtn:hover{
    background-color: rgba(100, 148, 237, 0.7);
    color: white;
    padding: 8px;
    font-weight: 500;
    font-size: 16px;
    transition: ease-in-out .2s;
    cursor: pointer;
}