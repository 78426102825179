.MokedScansMainSiteHourButton{
    padding-top: 20px;
    width: 100%;
    padding-bottom: 5px;
    border: none;
    border-bottom: solid 1px rgba(120, 190, 14, 0.308);
    background: none;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
}

.MokedScansMainSiteHourButton:hover{
    background-color: rgba(120, 190, 14, 0.1);
}