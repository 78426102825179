.endTransforms{
    position: fixed;
    z-index: 4;
    top: 0;
    right: 0;
    width: 100%;
    height: calc(100vh - 50px);
    backdrop-filter: blur(5px);
    background-color: rgba(255, 255, 255, 0.466);
    display: flex;
    justify-content: center;
    align-items: center;
}

.endTransformsInner{
    position: relative;
    padding: 20px;
    margin-right: 30%;
    width: 50%;
    min-height: 60%;
    background-color: rgba(255, 255, 255, 0.555);  
    backdrop-filter: blur(20px);
    border-radius: 5px;
    -webkit-box-shadow: 0 10px 30px 0 rgba(0,0,0,0.3);
    box-shadow: 0 10px 30px 0 rgba(0,0,0,0.3);
}

.endTransformsInnerExit{
    position: absolute;
    left: 10px;
    top: 10px;
    background: none;  
    color: rgb(114, 224, 129);
    font-size: 18px;
    background-color: none;
    border: none;
}

.endTransformsInnerExit:hover{
    cursor: pointer;
}

.endTransformsContainer{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.endTransformsTop{
    flex: 6;
    padding-bottom: 15px;
}

.endTransformsTopContainer{
    display: flex;
    flex-direction: row;
    margin-top: 15px;
    padding: 0 20px 0 20px;
    justify-content: center;
}

.endTransformsTopRight{
    flex: 5;
    display: flex;
    flex-direction: column;
}

.transformsInfoList{
    width: 50%;
    display: flex;
    flex-direction: column;
    border: none;
}

.transformsInfoItem{
    width: 100%;
    border-bottom: 2px inset rgba(12, 182, 77, 0.3);
    padding: 15.2px 0 15.2px 0;
    display: flex;
}

.transformsGuardsHoursFill{
    display: flex;
}

.guardsField{
    padding-right: 15px;
    font-size: 15px;
    margin-top: -3px;
}

.guardsNameHourPicker{
    width: 60px;
}

.guardsHoursList{
    width: 100%;
}

.guardsHoursItem{
    padding: 25px 0 5px 0;
}

.endTransformsTopLeft{
    flex: 5;
    display: flex;
    flex-direction: column;
}

.endTransformsBottom{
    flex: 4;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.endTransformsSideNote{
    width: 95%;
    border-radius: 5px;
    border: solid 1px rgba(128, 128, 128, 0.39);
    background-color: rgba(255, 255, 255, 0.555);  
    backdrop-filter: blur(20px);
}

.endTransformsBtn{
    width: 96.4%;
    padding: 10px;
    margin-top: 20px;
    border-radius: 3px;
    border: solid 1px rgba(12, 182, 77, 0.5);
    background: none;
    color: rgba(12, 182, 77, 0.705);
    font-size: 16px;
}

.endTransformsBtn:hover{
    background-color: rgba(12, 182, 77, 0.774);
    border: solid 0.5px rgba(12, 182, 77, 0.5);
    color: white;
    padding: 10px;
    font-weight: 500;
    font-size: 16px;
    transition: ease-in-out .2s;
    cursor: pointer;
}