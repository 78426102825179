.siteInformationList{
    padding: 0 5px 0 0;
    margin: 0;
    list-style: none;
}

.siteInformationItem:nth-child(odd){
    width: 100%;
    display: flex;
    align-items: center;
    padding: 15px 15px 15px 0;
    margin-bottom: 3px;
    font-size: 15px;
    font-weight: 400;
    background-color: rgba(120, 190, 14, 0.384); 
    border: none;
    border-bottom: 2px inset rgba(12, 182, 77, 0.5);
}

.siteInformationItem:nth-child(even){
    width: 100%;
    display: flex;
    align-items: center;
    padding: 15px 15px 15px 0;
    margin-bottom: 3px;
    font-size: 15px;
    font-weight: 400;
    background-color: rgba(221, 221, 221, 0.39);
    border: none;
    border-bottom: 2px inset rgba(12, 182, 77, 0.5);
}

.sideNoteList{
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    min-height: 50px;
    align-items:flex-start;
    padding: 15px 15px 15px 0;
    margin-bottom: 3px;
    font-size: 15px;
    font-weight: 500;
    background-color: rgba(221, 221, 221, 0.39);
    border: none;
    border-bottom: 2px inset rgba(12, 182, 77, 0.5);
    list-style: none;

}

.warningSignIcon{
    right: 10px;
    top: 5px;
    font-size: 70px !important;
    color: rgb(194, 14, 14);
    position: absolute;
}

.informationSideNote{
    margin-right: 70px;
    padding: 5px;
}

.siteInformationItemList{
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
    background-color: forestgreen;
}

.siteInformationItemItem{
    padding-top: 15px;
}
