.EntriesPageContainer{
    display: flex;
    width: 100%;
}

.backgroundImgMatara{
    display: flex;
    justify-content: center;
    position: relative;
}

.MataraLogoImg{
    position: absolute;
    width: 25%;
    bottom: 20px;
    left: 20px;
}