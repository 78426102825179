.pageShift{
    position: fixed;
    z-index: 4;
    top: 0;
    right: 0;
    width: 100%;
    height: calc(100vh);
    overflow-y: scroll;
    overflow-x: hidden;
    backdrop-filter: blur(5px);
    background-color: rgba(255, 255, 255, 0.466);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.pageShiftOuter{
    width: 65%;
    display: flex;
    backdrop-filter: blur(5px);
    background-color: rgba(255, 255, 255, 0.885);  
    border-radius: 5px;
    border-bottom-right-radius: 0 ;
    border-bottom-left-radius: 0 ;
    z-index: 5;
}

.outerShiftButtons{
    flex: 2;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.985);  
    backdrop-filter: blur(5px);
    padding: 20px;
    cursor: pointer;
}

.outerShiftButtonsPressed{
    -webkit-box-shadow: inset 0 -5px 5px 0 rgba(0,0,0,0.3);
    box-shadow: inset 0 -5px 5px 0px rgba(0,0,0,0.3);
}

.pageShiftInner{
    position: relative;
    width: 65%;
    min-height: 80%;
    height: 80%;
    max-height: 80%;
    overflow-y: scroll;
    overflow-x: hidden;
    backdrop-filter: blur(5px);
    background-color: rgba(255, 255, 255, 0.985);  
    border-radius: 5px;
    border-top-right-radius: 0 ;
    border-top-left-radius: 0 ;
    -webkit-box-shadow: 0 10px 30px 0 rgba(0,0,0,0.3);
    box-shadow: 0 10px 30px 0 rgba(0,0,0,0.3);
}

.pageShiftContainer{
    height: 95%;
    display: flex;
    flex-direction: column;
    padding: 20px;
}