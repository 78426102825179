.activeEntryInfoList{
    padding: 0 5px 0 0;
    margin: 0;
    list-style: none;
    display: flex;
    width: 100%;
    border-bottom: 2px inset rgba(12, 182, 77, 0.3);
    position: relative;
}

.activeEntryInfoItem{
    width: 23%;
    padding: 15px 0;
}

.activeEntryInfoItemName:hover{
    color:cornflowerblue;
    font-size: 17px;
    cursor: pointer;
}

.activeEntryInfoItemHour{
    margin-right: 10px;
}

.employExitIcon{
    color: rgb(180, 5, 5);
    position: absolute;
    top: 13px;
    left: 10px;
}

.employExitIcon:hover{
    color: rgb(196, 4, 4);
    font-size: 22px !important;
    cursor: pointer;
}