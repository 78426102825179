.newFormBtn{
    background: none;
    padding-top: 15px;
    margin-bottom: 10px;
    width: 200px;
    height: 60px;
    border: none;
    border-bottom: 3px inset rgba(12, 182, 77, 0.5);
    border-radius: 30px;
    font-weight: 600;
    font-size: 16px;
}

.newFormBtn:hover{
    cursor: pointer;
    background: #FFFFFF;
    background: -moz-linear-gradient(top, #FFFFFF 0%, #FFFFFF 92%, #71D97D 100%);
    background: -webkit-linear-gradient(top, #FFFFFF 0%, #FFFFFF 92%, #71D97D 100%);
    background: linear-gradient(to bottom, #FFFFFF 0%, #FFFFFF 92%, #71D97D 100%);
    border-bottom: 3px inset rgba(12, 182, 77, 0.911);
}

.transformsForm{
    padding: 20px;
}

.transformsFormInputContainer{
    display: flex;
}

.transformsFormInputBox{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.transformsFormInputBox:first-child{
    margin-left: 20px;
}

.transformsFormLabel{
    font-size: 15px;
    margin-bottom: 5px;
}

.transformsFormInput{
    padding: 8px;
    border-radius: 3px;
    border: solid 1px rgba(128, 128, 128, 0.39);
    font-size: 14px;
    font-weight: 300;
}

.transformsFormInput:focus{
    outline: none;
    padding: 10px;
    border: 1px solid rgba(39, 223, 14, 0.568);
    transition: border .2s 
}

.transformsFormCheckContainer{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.transformsFormCheckboxItem{
    display: flex;
    margin-top: 20px;
}

.transformsFormCheckboxLabel{
    margin-right: 30px;
}

input[type=checkbox] {
    cursor: pointer;
    width: 0px;
    height: 0px;
  }
  
  input[type=checkbox]:checked:before {
    content: "";
    display: block;
    width: 18px;
    height: 18px;
    background-color: rgba(38, 223, 14, 0.322);
    transition: all 0.1s linear;
  }
  
  
  input[type=checkbox]:before {
    content: "";
    display: block;
    width: 15px;
    height: 15px;
    border: 1px solid rgba(39, 223, 14, 0.568);
    border-radius: 3px;
    background-color: white;
  }

  .createFormBtn{
      display: flex;
      justify-content: center;
  }

  .transformsFormError{
      display: flex;
      justify-content: center;
      margin: 20px;
  }
  
  .entryFormLabel{
    font-size: 15px;
    margin-bottom: 6px;
  }

  .editFormBtn:hover{
    cursor: pointer;
    background: #FFFFFF;
    background: -moz-linear-gradient(top, #FFFFFF 0%, #FFFFFF 92%, cornflowerblue 100%);
    background: -webkit-linear-gradient(top, #FFFFFF 0%, #FFFFFF 92%, cornflowerblue 100%);
    background: linear-gradient(to bottom, #FFFFFF 0%, #FFFFFF 92%, cornflowerblue 100%);
    border-bottom: 3px inset rgb(100, 148, 237);
  }
  
  .cancelFormBtn{
    background: none;
    padding-top: 15px;
    width: 200px;
    height: 60px;
    margin-right: 10px;
    border: none;
    border-bottom: 3px inset rgba(12, 182, 77, 0.5);
    border-radius: 30px;
    font-weight: 600;
    font-size: 16px;
  }

  .cancelFormBtn:hover{
    cursor: pointer;
    background: #FFFFFF;
    background: -moz-linear-gradient(top, #FFFFFF 0%, #FFFFFF 92%, #D91A1A 100%);
    background: -webkit-linear-gradient(top, #FFFFFF 0%, #FFFFFF 92%, #D91A1A 100%);
    background: linear-gradient(to bottom, #FFFFFF 0%, #FFFFFF 92%, #D91A1A 100%);
    border-bottom: 3px inset rgba(255, 0, 0, 0.726);
  }