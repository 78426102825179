.finishedTransformsListContainer{
    padding-bottom: 20px;
}

.transformsDateTitle{
    border: none;
    padding: 5px 10px 5px 0;
    margin-bottom: 25px;
    border-top: 1px solid rgba(120, 190, 14, 0.418);
    border-bottom: 1px solid rgba(120, 190, 14, 0.418);
}

.finishedTransformsListWrapper{
    padding: 10px;
}

.finishedTransformsList{
    list-style: none;
    margin: 0;
    padding: 0;
}

.finishedTransformsListItem{
    height: 80px;
    padding: 10px;
    border-radius: 5px;
    -webkit-box-shadow: 0px 3px 7px 0px rgba(0,0,0,0.36); 
    box-shadow: 0px 3px 7px 0px rgba(0,0,0,0.36);
    background-color: rgba(120, 190, 14, 0.384);
    margin-bottom: 15px;
}

.finishedTransformsListItemContainer{
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.finishedTransformsListItemRight{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.driverName{
    font-size: 18px;
}

.finishedTransformsListItemLeft{
    display: flex;
    align-content: center;
}

.openTransformsFormBtn{
    background: none;
    border: none;
    font-size: 15px;
    font-weight: 700;
    color: rgb(81, 126, 14);
}

.transformIcon{
    width: 20px;
    height: 20px;
}

.finishedTransformsListItemLeft:hover > .openTransformsFormBtn{
    cursor: pointer;
    font-size: 14px;
}

.finishedTransformsListItemLeft:hover > .transformIcon{
    cursor: pointer;
    width: 17px;
    height: 17px;
}