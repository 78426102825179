.malfunctionTypeContainer{
    width: 90%;
}

.malfunctionTypeContainerList{
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
}

.malfunctionItem{
    margin: 5px;
    display: flex;
    font-weight: 700;
    font-size: 14px;
}

.malfunctionItem:hover{
    font-size: 15px;
    color: red;
    cursor:pointer;
    transition: ease-in-out .1s;
    cursor: pointer;
}

.malfunctionTypeDot{
    border-radius: 50%;
    color: green;
    height: 10px;
    width: 10px;
    background-color: green;
}