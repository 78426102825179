.RightMalfunctions{
    flex:1;
    height: calc(100vh - 50px);
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
}

.malfunctionsWrapper{
    padding: 10px;
    height: 100%;
    width: 100%;
    display: flex;
    padding-right: 0;
    justify-content: flex-start;
    list-style: none;
    flex-direction: column;
}

.groupMalfunctionListInput{
    padding: 15px 5px;
    margin: 0 5px;
    border-radius: 5px;
    font-size: 15px;
}

.item{
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: flex-start;
    font-size: 16px;
    font-weight: 700;
    background: none;
    border: none;
    transition: filter 300ms;
    transition: background 300ms;
}

.item:hover{
    background-color: rgba(221, 241, 221, 0.904);
    cursor: pointer;
}

.activeItem{
    background-color: rgba(221, 241, 221, 0.904);
}