.clientUserFormContainer{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    align-items: space-around;
}

.clientUserFormContainerTop{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.clientUserFormContainerPermission{
    height: 200px;
    width: 90%;
}

.clientUserFormPermissionList{
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-flow: row wrap;
}

.permitItem{
    height: 20px;
    width: 15%;
    padding: 12px;
    margin: 5px;
    display: flex;
    justify-content: center;
    font-weight: 700;
    font-size: 14px;
    border-radius: 5px;
    -webkit-box-shadow: 0 10px 10px 0 rgba(0,0,0,0.1);
    box-shadow: 0 10px 10px 0 rgba(0,0,0,0.1);
    background-color: rgba(120, 190, 14, 0.5);
}

.permitItem:hover{
    font-size: 15px;
    color: red;
    cursor:pointer;
    transition: ease-in-out .1s;
    cursor: pointer;
}

.mokedWorkerWrapperUserClient{
    display: flex;
    padding: 20px;
    width: 100%;
    height: 80%;
    -webkit-box-shadow: 0 10px 30px 0 rgba(0,0,0,0.3);
    box-shadow: 0 10px 30px 0 rgba(0,0,0,0.3);
    margin: 13px;
}

.buttonWrapperUserClient{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.BottomFormMokedWorkersAhmashMargin{
    margin-bottom: 15px;
}