.activeTransformsItem{
    padding: 10px;
    position: relative;
    margin: 5px;
    min-height: 55px;
    width: 94.3%;
    border-radius: 5px;
    -webkit-box-shadow: 0px 3px 7px 0px rgba(0,0,0,0.36); 
    box-shadow: 0px 3px 7px 0px rgba(0,0,0,0.36);
    background-color: rgba(120, 190, 14, 0.384); 
    backdrop-filter: blur(5px);
}

.activeTransformsTitle:hover{
    color:cornflowerblue;
    font-size: 17px;
    cursor: pointer;
}