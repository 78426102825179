.mokedScansSchemaFormContainer{
    display: flex;
    flex-direction: column;
    height: 95%;
    width: 100%;
    align-items: space-between;
}

.mokedScansSitesIncludedSentence{
    margin-bottom: 15px;
}

.mokedScansButtonsAdd{
    background-color: rgba(120, 190, 14, 0.5);
    border: none;
    padding: 20px;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 600;
    color: white;
    -webkit-box-shadow: 0 10px 10px 0 rgba(0,0,0,0.1);
    box-shadow: 0 10px 10px 0 rgba(0,0,0,0.1);
    transition: filter 300ms;
    margin-bottom: 15px;
    cursor: pointer;
    margin-left: 10px;
}

.mokedScansButtonsSubtract{
    background-color: rgba(192, 11, 11, 0.5);
    border: none;
    padding: 20px;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 600;
    color: white;
    -webkit-box-shadow: 0 10px 10px 0 rgba(0,0,0,0.1);
    box-shadow: 0 10px 10px 0 rgba(0,0,0,0.1);
    transition: filter 300ms;
    margin-bottom: 15px;
    cursor: pointer;
    margin-right: 10px;
}

.mokedScansButtonsAdd:hover{
    filter: brightness(1.1);
}

.mokedScansButtonsSubtract:hover{
    filter: brightness(1.1);
}

.mokedScansSiteListContainer{
    overflow-y: scroll;
}