.siteInformationSection{
    height: calc(100vh - 150px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.siteInformationWrapper{
    display: flex;
    padding: 20px;
    width: 100%;
    height: 90%;
    -webkit-box-shadow: 0 10px 30px 0 rgba(0,0,0,0.3);
    box-shadow: 0 10px 30px 0 rgba(0,0,0,0.3);
    margin: 13px;
}

.siteInformationContainer{
    overflow-y: visible;
    overflow-x: hidden;    
    width: 50%;
    height: 100%;
    border-right: 2px inset rgba(12, 182, 77, 0.3);
    padding-right: 20px;
}

.siteInformationContainerTop{
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
}

.siteInfoList{
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    position: relative;
}

.AddIconInfo{
    position: absolute;
    left: 45px;
    font-size: 15px;
}

.AddIconInfo:hover{
    font-size: 13px;
    cursor: pointer;
}

.siteInfoListList{
    position: relative;
    width: 80%;
}

.siteInfoListItem{
    font-size: 17px;
}

.mokedTypeMangerInfo{
    display: flex;
    padding: 20px;
    margin-left: 50px;
}

.mokedTypeMangerInfoCheckbox{
    margin-right: 50px;
}

.mokedTypeMangerInfoLabel{
    margin-right: 30px;
}