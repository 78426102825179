.ScoutContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  gap: 50px;
}

.ScoutChooseTypeButton {
    width: 30%;
    height: 80px;
    font-weight: bold;
    font-size: 20px;
    border-radius: 10px;
    border: none;
    background-color: rgba(106, 156, 31, 0.733);  
    color: white;
  }
  
  .ScoutChooseTypeButton:hover {
    background-color: rgba(106, 156, 31, 0.9);
    cursor: pointer;
  }
  
  .ScoutChooseTypeButton:active {
    transform: scale(0.95);
  }
  

.ScoutTypeLayoutContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin: 50px auto 0;
  width: 30%;
}

.ScoutTypeLayoutBsckButton {
  width: 60px;
  height: 60px;
  font-weight: bold;
  font-size: 20px;
  border-radius: 50%;
  border: none;
  background-color: rgba(106, 156, 31, 0.733);  
  color: white;
  align-self: start;
}

.ScoutTypeForm{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 70%;
    margin: 50px auto 0;
}

.ScoutTypeFormLabel{
    font-size: 15px;
    font-weight: bold;
    width: 100%;
}

.ScoutFormInput {
    width: 95%;
    height: 20px;
    font-size: 15px;
    padding: 10px;
    border: none;
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    position: relative;
    margin: 15px auto 0;
}

.ScoutTypeFormButton{
    width: 100%;
    height: 60px;
    font-weight: bold;
    font-size: 20px;
    border-radius: 10px;
    border: none;
    background-color: rgba(106, 156, 31, 0.733);  
    color: white;
}

