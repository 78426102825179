.TransformsFormsContainer{
    display: flex;
    width: 100%;
}

.TransformsFormsLeft{
    flex: 7;
    height: calc(100vh - 50px);
    overflow-y: visible;
    overflow-x: hidden;
}