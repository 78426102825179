.imageListWrapper{
    display: flex;
    width: 100%;
}

.imageDisplayWrapper{
    display: flex;
    flex-direction: column;
}

.imageListContainer{
    width: 200px;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    margin: 20px;
    box-shadow: 0 0 10px  rgba(0, 0, 0, 0.35);
}