.malfunctionContainer{
    padding: 5px;
}

.malfunctionTitle{
    padding: 5px 0;
    margin: 15px 0 5px 0;
}

.malfunctionTitle:first-child{
    margin-top: -10px;
}

.urgentItem{
    padding:  10px 0 ;
    margin: 5px 0px;
    list-style: none;
    display: flex;
    width: 100%;
    border-bottom: 2px inset rgba(12, 182, 77, 0.3);
    position: relative;
}

.malfunctionTitleContainer{
    display: flex;
    margin-top: 30px;
}

.copyBtn{
    background: none;
    border: 2px inset rgba(12, 182, 77, 0.3);
    border-radius: 5px;
    font-weight: 800;
    margin-top: -15px;
    margin-right: 15px;
}

.copyBtn:hover{
    background-color: rgba(12, 182, 77, 0.103);
    cursor: pointer;
}