.feed{
    flex: 6;
}

.feedWrapper{
    padding: 20px;
}

.activeEntryListItem{
    display: flex;
    flex-flow: row wrap;
}