.createContainer{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.createForm{
    display: flex;
    flex-direction: column;
    width: 30%;
    background-color: rgba(80, 96, 189, 0.5);
    border-radius: 5px;

}

.inputItem{
    font-size: 22px;
    padding: 10px;
    margin: 10px;
    border: none;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.5);
}

.inputBtn{
    padding: 10px;
    margin: 10px;
    border: none;
    border-radius: 5px;
    background-color: rgba(80, 96, 189, 0.5);
    color: white;
    font-weight: 700;
    font-size: 25px;
    cursor: pointer;
}

.formLabel:first-child{
    margin-top: 10px;
}

.formLabel{
    font-size: 20px;
    margin-right: 5px;
}